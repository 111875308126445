<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.smAndDown"
    hide-overlay
    persistent
    width="80%"
    max-height="80vh"
  >
    <v-card class="px-2">
      <v-card-title class="py-2">
        <div class="headline">
          {{ caption }} Training: &ensp;
          <span class="primary--text">
            {{ isEdit ? existingTraining.no : "" }}
          </span>
        </div>
        <v-chip
          v-if="isEdit"
          label
          small
          :color="getColor(formData.status).color"
          class="mr-2"
        >
          {{ formData.status }}
        </v-chip>
        <v-spacer />
        <div v-if="formData.submittedBy === user.number">
          <div v-if="formData.status === 'Open'">
            <!-- send for approval -->
            <v-btn
              depressed
              @click="sendForApproval(formData.no)"
              v-if="canEdit"
              class="deep-blue lighten-4 deep-blue--text text--darken-4 mx-1"
              color="blue lighten-4"
              label
            >
              send for approval
              <v-icon color="blue--text text--darken-4" class="ml-1" small>
                mdi-send
              </v-icon>
            </v-btn>
            <!-- delete training -->
            <v-btn
              depressed
              v-if="canEdit"
              @click="deleteTraining(formData)"
              class="deep-orange lighten-4 deep-orange--text text--darken-4 mx-1"
              color="error lighten-4"
              label
            >
              <span class="error--text text--darken-4"> delete </span>

              <v-icon color="error darken-4" class="ml-1" small>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
          <div v-if="formData.status === 'Pending Approval'">
            <!-- cancel approval -->
            <v-btn
              @click="cancelApprovalRequest(formData.no)"
              class="mx-1"
              color="orange lighten-4"
              label
              depressed
            >
              <span class="orange--text text--darken-4">
                Cancel approval request
              </span>

              <v-icon color="orange darken-4" class="ml-1" small>
                cancel_schedule_send
              </v-icon>
            </v-btn>
          </div>
        </div>

        <!-- close dialog -->
        <v-btn
          @click="close()"
          class="deep-orange lighten-4 deep-orange--text text--darken-4"
          elevation="0"
        >
          close
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text
        class="pt-3 overflow-y-auto overflow-x-hidden"
        style="max-height: 60vh"
      >
        <v-row>
          <!-- requestedBy -->
          <v-col cols="12">
            <v-text-field
              label="Requested By"
              dense
              disabled
              outlined
              :value="submittedBy"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-form v-model="isValid" ref="applicationForm">
          <v-row>
            <!-- description -->
            <v-col cols="12">
              <v-textarea
                outlined
                rows="1"
                auto-grow
                v-model="formData.description"
                label="Description"
                :rules="rules.required"
                :disabled="!canEdit && isEdit"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <!-- objectives -->
            <v-col cols="12">
              <v-textarea
                outlined
                rows="3"
                auto-grow
                v-model="formData.trainingObjectives"
                label="Your Objectives"
                placeholder="What are you expecting to learn on this course?"
                :rules="rules.required"
                :disabled="!canEdit && isEdit"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <!-- Impact -->
            <v-col cols="12">
              <v-textarea
                outlined
                rows="3"
                auto-grow
                v-model="formData.trainingImpact"
                label="Impact"
                placeholder="How will the acquired knowledge or skill help improve your performance and/or prepare you for more advanced responsibilities?"
                :rules="rules.required"
                :disabled="!canEdit && isEdit"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-card flat>
            <v-card-title> Programme </v-card-title>
            <v-row>
              <v-col cols="12" lg="6" sm="12">
                <v-text-field
                  outlined
                  v-model="formData.trainingNeedLines[0].programmeDescription"
                  label="Description"
                  :rules="rules.required"
                  :disabled="!canEdit && isEdit"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6" sm="12">
                <v-text-field
                  v-model="formData.trainingNeedLines[0].methodOfStudy"
                  label="Mode of Study"
                  outlined
                  :rules="rules.required"
                  :disabled="!canEdit && isEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" sm="12">
                <v-text-field
                  outlined
                  v-model="formData.trainingNeedLines[0].trainerName"
                  label="Training Provider (Institution)"
                  :rules="rules.required"
                  :disabled="!canEdit && isEdit"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6" sm="12">
                <v-text-field
                  outlined
                  v-model="formData.trainingNeedLines[0].studyTimeRequired"
                  label="Study time required"
                  :rules="rules.required"
                  :disabled="!canEdit && isEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" sm="12">
                <v-text-field
                  outlined
                  v-model.number="formData.trainingNeedCosts[0].amountExclVAT"
                  label="Total cost of Programme"
                  :rules="rules.required"
                  :disabled="!canEdit && isEdit"
                ></v-text-field>
              </v-col>

              <v-col cols="12" lg="6" sm="12">
                <v-autocomplete
                  v-model="formData.trainingNeedCosts[0].expenseCode"
                  :items="billingItems"
                  :item-text="(item) => item.description"
                  :item-value="(item) => item.code"
                  :hide-details="false"
                  label="Select Expense Type"
                  outlined
                  :rules="rules.required"
                  :disabled="isEdit"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" sm="12">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.trainingNeedLines[0].startDate"
                      outlined
                      :rules="rules.required"
                      label="Start date"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.trainingNeedLines[0].startDate"
                    :min="startDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" lg="6" sm="12">
                <v-menu
                  ref="menu3"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.trainingNeedLines[0].startTime"
                      label="Start time"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!formData.trainingNeedLines[0].startDate"
                      :rules="rules.required"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="formData.trainingNeedLines[0].startTime"
                    format="24hr"
                    :allowed-minutes="allowedStep"
                    use-seconds
                  />
                </v-menu>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6" sm="12">
                <v-menu
                  ref="menu"
                  v-model="menu3"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.trainingNeedLines[0].endDate"
                      :disabled="!formData.trainingNeedLines[0].startDate"
                      outlined
                      :rules="rules.required"
                      label="End date"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formData.trainingNeedLines[0].endDate"
                    :min="formData.trainingNeedLines[0].startDate"
                  ></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" lg="6" sm="12">
                <v-menu
                  ref="menu3"
                  v-model="menu4"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formData.trainingNeedLines[0].endTime"
                      label="End time"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!formData.trainingNeedLines[0].endDate"
                      :rules="rules.required"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="formData.trainingNeedLines[0].endTime"
                    format="24hr"
                    :allowed-minutes="allowedStep"
                    use-seconds
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-card>

          <v-divider></v-divider>

          <!-- attachments -->
          <Attachments
            v-if="!isNew || formData.attachments.length !== 0"
            :isSupervisor="isSupervisor"
            :formData="formData"
            :isEdit="isEdit"
            :canEdit="canEdit"
            :user="user"
            :isNew="isNew"
          />

          <!-- approval entries -->
          <ApprovalEntries
            :formData="formData"
            v-if="formData.status !== 'Open' && formData.status !== ''"
          />
        </v-form>
      </v-card-text>

      <v-divider v-if="!isNew" />
      <v-card-actions
        v-if="
          !isNew &&
          (formData.submittedBy === user.number || formData.submittedBy === '')
        "
      >
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="submit('andSubmit')"
          v-if="formData.submittedBy === user.number"
        >
          send for approval
        </v-btn>
        <v-btn color="primary" depressed @click="submit()"> save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AuthService from "@/packages/cassandra-base/modules/auth/AuthService";
import helpers from "@kinetics254/cassandra-base/utilities/helpers/helper";
import RouterMixin from "../../../router/RouterMixin.js";
import Attachments from "./TrainingAttachment.vue";
import ApprovalEntries from "./ApprovalEntries";

export default {
  name: "TrainingCard",
  components: { Attachments, ApprovalEntries },
  mixins: [RouterMixin],
  data: function () {
    return {
      dialog: true,
      isValid: false,
      isEdit: false,
      formData: {
        description: "",
        trainingObjectives: "",
        trainingImpact: "",
        documentDate: "",
        no: "",
        status: "",
        submittedBy: "",
        submittedName: "",
        supervisor: "",
        trainingNeedLines: [
          {
            endDate: "",
            endTime: "",
            methodOfStudy: "",
            programmeCode: "",
            programmeDescription: "",
            startDate: "",
            startTime: "",
            studyTimeRequired: "",
            trainer: "",
            trainerName: "",
            trainingDetails: "",
          },
        ],
        trainingNeedCosts: [
          {
            expenseCode: "",
            amountExclVAT: "",
          },
        ],
        attachments: [],
      },
      stackedMenu: true,
      presence: true,
      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      allowedStep: (m) => m % 10 === 0,
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.params.code) {
        v.isEdit = true;
        v.$store.dispatch(
          "Training/getTraining",
          v.decodeRoute(to.params.code)
        );
      }
      v.$store.dispatch("Setup/getTrainingProgrammes");
      v.$store.dispatch("Setup/getTrainees");
      v.$store.dispatch("Setup/getLeaveEmployees");
      v.$store.dispatch("Requisition/getBillingItems");
    });
  },
  mounted() {
    Event.$on("closeDialog", () => {
      this.close();
    });
  },
  computed: {
    rules() {
      return {
        required: [(v) => !!v || "field is required"],
      };
    },
    user() {
      return AuthService.user.profile;
    },
    existingTraining() {
      return this.$store.getters["Training/trainingGetters"]("training");
    },
    billingItems() {
      return this.$store.getters["Requisition/reqGetters"](
        "billingItems"
      ).filter((item) => item.type.toLowerCase() === "g/l account");
    },
    isSupervisor() {
      return AuthService.user.profile.isHOD;
    },
    canEdit() {
      return (
        this.formData.submittedBy === this.user.number &&
        this.formData.status === "Open"
      );
    },
    caption() {
      if (!this.isEdit) {
        return "Add";
      } else {
        return this.canEdit ? "Edit" : "";
      }
    },
    isNew() {
      return [
        "Released",
        "Pending Approval",
        "Closed",
        "Rejected",
        "Active",
      ].includes(this.formData.status);
    },
    submittedBy() {
      return this.isEdit
        ? this.formData.submittedBy + ":  " + this.formData.submittedName
        : this.user.number +
            ":  " +
            this.user.first_name +
            " " +
            this.user.last_name;
    },
  },
  methods: {
    close() {
      this.$router.push({ name: "TrainingList" });
    },
    submit: function (type) {
      if (!this.isValid) {
        this.$refs.applicationForm.validate();
      } else {
        if (this.formData.trainingNeedLines.length === 0) {
          Event.$emit(
            "ApiWarning",
            "Programme is needed to submit the Training Request"
          );
        } else {
          this.formData.submittedBy = this.user.number;
          this.formData.documentDate = this.date;
          this.formData.trainingNeedLines.map((item) => {
            delete item.submittedName;
            return item;
          });
          type ? (this.formData.sendForApproval = true) : false;
          this.isEdit
            ? this.$store.dispatch("Training/updateTraining", this.formData)
            : this.$store.dispatch("Training/addTraining", this.formData);
        }
      }
    },
    sendForApproval: function (no) {
      this.$store.dispatch("Training/sendForApproval", { docNo: no });
    },
    cancelApprovalRequest: function (data) {
      const no = {
        docNo: data,
      };
      this.$store.dispatch("Training/cancelApproval", no);
    },
    deleteTraining: function (data) {
      const params = {
        title: ` Training request ${data.no}`,
        text: "Are you sure you want to delete this training request?",
        onConfirm: () => {
          this.$store.dispatch("Training/deleteTraining", data);
        },
      };
      this.$confirm.show(params);
    },
    getColor(status) {
      return helpers.getColor(status);
    },
  },
  watch: {
    existingTraining: {
      handler: function (n, o) {
        if (this.isEdit) {
          this.formData = { ...this.existingTraining };

          if (this.existingTraining.trainingNeedCosts.length === 0) {
            this.formData.trainingNeedCosts = [
              {
                expenseCode: "",
                amountExclVAT: "",
              },
            ];
          }
        }
      },
    },
  },
};
</script>

<style>
.avatars-group__item {
  cursor: default;
  transition: all 0.1s ease-out;
}
.avatars-group__item.more {
  align-items: center;
  display: flex;
}
.avatars-group__item.more:hover {
  transform: none;
}
.avatars-group__item:hover {
  transform: translateY(-4px);
  z-index: 1;
}
.avatars-group.stacked {
  display: flex;
  flex-direction: row;
  direction: ltr;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.avatars-group.stacked > * {
  margin-right: -8px;
}
.avatars-group.stacked > *:last-of-type {
  padding-right: 16px;
}
.avatars-group .v-avatar {
  box-shadow: 0px 0px 0px 2px #fff inset;
}
</style>
